import React from "react";
import Navbar from "../Navbar/Navbar";
import "./Header.scss";
const Header = () => {
  return (
    <div className="header">
      <div className="header__title">
        {/* <p>Get fresh produce</p> */}
        <a href="/">
          <img src="/images/logo.png" alt="logo" />
        </a>
        <div className="header__buttons">
        <Navbar />
        <a href="#contact"><button >Get in touch</button></a>
        </div>
        
      </div>
    </div>
  );
};

export default Header;
