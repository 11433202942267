import React from "react";
import Header from "../Header/Header";
import Carousel from "../Carousel/Carousel";
import Footer from "../Footer/Footer";
import Experience from "../Experience/Experience";
import Contact from "../Contact/Contact";
import "./Home.scss";
import Testimonials from "../Testimonials/Testimonials";
import Curves from "../Curves/Curves";

const Home = () => {
  return (
    <div className="main">
      <header>
        <Header />
      </header>

      <section>
        <Carousel />
      </section>
      <section className="home__section" id="offer">
        <Curves bgColor="#F2C24C" top={false}>
          <div className="home__text">
            <h1>
              What we <span className="offer">offer</span>
            </h1>
            <p style={{ fontWeight: "bold" }}>
              Naturally grown produce | Climate friendly practices | Your family
              farmer | Doorstep delivery within 5 hours of harvest
            </p>
            {/* <p>
              Our naturally farmed vegetables are seasonal, non-GMO and
              chemical-free which ensures that you and your family receive the
              freshest and most nutritious food directly from the farm gate.
              Farm visits will be available soon.
            </p> */}
          </div>
        </Curves>
      </section>
      <section id="experience">
        <Experience />
      </section>
      <section className="home__section" >
        <Curves bgColor="#4C894E" bottom={false}>
          <div className="home__text">
            <h1>
              What you <span>get</span>
            </h1>
            <p>
              6-8 kgs of naturally grown seasonal vegetables delivered right at
              your doorstep, once a week. 
              <br />
              Price: Rs. 4,000 per month (inclusive of delivery to select areas
              in Gurgaon and South Delhi)
            </p>
            <p
              onClick={() => window.open("/list.pdf")}
              className="download__list"
            >
              Click to see list of vegetables
            </p>
          </div>
        </Curves>
      </section>
      {/* <section>
        <Subscription />
      </section> */}

      <section className="content__section" id="testimonials">
        <Testimonials />
      </section>

      {/* <section className="content__section" style={{background:"white"}}>
        <div className="page__content reverse">
          <img src="/images/carousel1.jpg" alt="img" />
          <div className="text__content">
            <h1>
              Our <span>Team</span>
            </h1>
            <p>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Atque
              vitae dicta est iure! Ipsa ratione, illum sed reprehenderit
              nesciunt provident odio. Laborum, in quis. Iusto. Lorem ipsum
              dolor, sit amet consectetur adipisicing elit. Sapiente ullam
              quibusdam eius, voluptatum fuga dolorum nemo voluptate aperiam
              quia consequuntur, accusamus saepe, enim quas sunt error
              similique? Veritatis officia omnis obcaecati consectetur natus
              soluta perferendis harum provident deserunt culpa sequi, veniam
              consequuntur asperiores doloremque debitis minima sit odit dolor
              sapiente.
            </p>
            <p>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Atque
              vitae dicta est iure! Ipsa ratione, illum sed reprehenderit
              nesciunt provident odio. Laborum, in quis. Iusto.
            </p>
          </div>
        </div>
      </section> */}

      <section className="content__section" id="contact">
        <Contact />
      </section>

      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default Home;
