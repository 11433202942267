import React, { useState } from "react";
import Header from "../Header/Header";
import "./FAQs.scss";
import Footer from "../Footer/Footer";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

const FAQComponent = ({pdfHandler}) => {
  const [expanded, setExpanded] = useState({});

  const handleToggle = (question) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [question]: !prevExpanded[question],
    }));
  };
  return (
    <div className="faq__component">
      <div className="faq__question">
        <strong>
          Q: Are your vegetables truly chemical-free?
          <button onClick={() => handleToggle("chemicalFree")}>
            {expanded["chemicalFree"] ? "-" : "+"}
          </button>
        </strong>
        {expanded["chemicalFree"] && (
          <p>
            A: Absolutely! All our vegetables are grown without the use of any
            harmful chemicals. We use natural and regenerative farming practices
            to ensure that you receive the healthiest of produce.
          </p>
        )}
      </div>
      <div className="faq__question">
        <strong>
          Q: Where are your vegetables grown?
          <button onClick={() => handleToggle("vegetablesGrown")}>
            {expanded["vegetablesGrown"] ? "-" : "+"}
          </button>
        </strong>
        {expanded["vegetablesGrown"] && (
          <p>
            A: Our vegetables are locally grown in the suburbs of Gurgaon, at
            the foothills of the Aravallis. By producing locally, we reduce the
            environmental impact of transportation.
          </p>
        )}
      </div>

      <div className="faq__question">
        <strong>
          Q: What can I expect to receive in my weekly box?
          <button onClick={() => handleToggle("weeklyBox")}>
            {expanded["weeklyBox"] ? "-" : "+"}
          </button>
        </strong>
        {expanded["weeklyBox"] && (
          <p>
            Your weekly box will typically include 6-8 kgs of seasonal
            vegetables along with some herbs. This is usually sufficient for a
            family of 3-4 people. For a season-wise list of the vegetables that
            you can expect, please click <span onClick={pdfHandler} style={{color:"#F2C24C",cursor:"pointer"}}>here</span>.
          </p>
        )}
      </div>

      <div className="faq__question">
        <strong>
          Q: What farming practices do you follow?
          <button onClick={() => handleToggle("farmingPractices")}>
            {expanded["farmingPractices"] ? "-" : "+"}
          </button>
        </strong>
        {expanded["farmingPractices"] && (
          <p>
            A: We practice sustainable and eco-friendly farming methods. This
            includes crop rotation, companion planting, and organic
            fertilization to promote soil health and biodiversity while avoiding
            the use of synthetic chemicals.
          </p>
        )}
      </div>

      <div className="faq__question">
        <strong>
          Q: Can I customize my vegetable box in a subscription?
          <button onClick={() => handleToggle("customizeBox")}>
            {expanded["customizeBox"] ? "-" : "+"}
          </button>
        </strong>
        {expanded["customizeBox"] && (
          <p>
            A: Unfortunately, we do not offer this option at present. The
            produce from the farm is distributed equally between all the
            members. This ensures that there is minimal wastage and the land is
            used in the most efficient and productive manner.
          </p>
        )}
      </div>

      <div className="faq__question">
        <strong>
          Q: What is the harvesting and delivery schedule?
          <button onClick={() => handleToggle("harvestingSchedule")}>
            {expanded["harvestingSchedule"] ? "-" : "+"}
          </button>
        </strong>
        {expanded["harvestingSchedule"] && (
          <p>
            We typically harvest vegetables once a week and ensure that they are
            delivered to you within 4-6 hours, so that you receive them at peak
            freshness.
          </p>
        )}
      </div>

      <div className="faq__question">
        <strong>
          Q: Where all do you deliver?
          <button onClick={() => handleToggle("deliveryAreas")}>
            {expanded["deliveryAreas"] ? "-" : "+"}
          </button>
        </strong>
        {expanded["deliveryAreas"] && (
          <p>
            A: We currently deliver in most parts of Gurgaon and some areas of
            South Delhi such as Vasant Vihar. Please get in touch with our team
            to check if your area is currently serviceable.
          </p>
        )}
      </div>

      <div className="faq__question">
        <strong>
          Q: How can I get involved in your community-supported agriculture
          (CSA) program?
          <button onClick={() => handleToggle("CSAProgram")}>
            {expanded["CSAProgram"] ? "-" : "+"}
          </button>
        </strong>
        {expanded["CSAProgram"] && (
          <p>
            A: If you reside in Gurgaon or South Delhi, join our waitlist and
            our team will get back to you as soon as we have more slots open up!
          </p>
        )}
      </div>
    </div>
  );
};
const FAQs = () => {
    const [showPdf, setShowPdf] = useState(false);

    const pdfHandler = () => {
        setShowPdf(true);
    };
  return (
    <div className="faqs">
      <Header />
      <section className="content__section__faqs">
        <div className="page__content">
          <img src="/images/carousel1.jpg" alt="img" />
          <div className="text__content">
            <h1>
              Frequently Asked <span>Questions</span>
            </h1>
            <FAQComponent pdfHandler={pdfHandler}/>
          </div>
        </div>
      </section>

      <Footer />

      <div>
        {showPdf && (
          <div className="fullscreen-overlay">
            <div className="overlay-content">
              <div className="header">
                <span className="close-btn" onClick={() => setShowPdf(false)}>
                  &times;
                </span>
              </div>
              <div
                className="overlay__form"
                style={{ height: "100%", width: "100%", margin: "auto" }}
              >
                <Worker
                  workerUrl={`https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js`}
                >
                  <Viewer
                    fileUrl={"/list.pdf"}
                    style={{ height: "100%", width: "100%" }}
                  />
                </Worker>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FAQs;
