import React from "react";
import {
  // FaSquareFacebook,
  FaInstagram,
  // FaSquareXTwitter,
  // FaLinkedin,
  FaPhone,
} from "react-icons/fa6";
import { IoLocationSharp, IoMail } from "react-icons/io5";
import "./Footer.scss";
const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__content">
        {/* <div className="our__story">
          <h1>Our Story</h1>

          <img src="/images/logo.png" alt="logo" />
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim,
            asperiores.
          </p>
          <h3>Read more</h3>
        </div> */}
        {/* <div className="info">
          <h1>Information</h1>
          <div>
            <li>Terms of Service</li>
            <li>Privacy Policy</li>
            <li>Refund Policy</li>
            <li>Terms and Conditions</li>
            <li>Shipping Policy</li>
          </div>
        </div> */}
       
        <div className="footer__contact">
          <h1>Contact<span style={{color:"orange"}}> Information</span></h1>
          <div>
            <li>
              <IoLocationSharp size={20} />
              <p>Arjun Marg, DLF Phase 1, Gurgaon</p>
            </li>
            <li>
              <IoMail size={20} />
              <p>localharvestclub@gmail.com</p>
            </li>
            <li>
              <FaPhone size={20} />
              <p>9810750546</p>
            </li>
          </div>
        </div>
        <div className="get__in__touch">
          <h1>Follow us </h1>
          <div className="social__icons">
            {/* <FaSquareFacebook size={20} className="social__icon"/> */}
           <a href="https://www.instagram.com/localharvestclub?igsh=MTR1Y2J0amd1YXZyYQ%3D%3D&utm_source=qr">
            <FaInstagram size={20} className="social__icon"/></a>
            {/* <FaSquareXTwitter size={20} className="social__icon"/>
            <FaLinkedin size={20} className="social__icon"/> */}
          </div>
        </div>
      </div>
      
      <hr />
      <div className="copyright">Naturebox Agritech</div>
    </div>
  );
};

export default Footer;
