export const TestimonialData = [
  {
    id: 1,
    name: "Anshuman Ghosh",
    subtitle:
      "One of the best things about joining Local Harvest Club has been that my kids now eat more veggies! Infact, they look forward to receiving the weekly produce and trying out simple salad recipes",
  },
  // {
  //   id: 2,
  //   name: "Avantika Kapoor",

  //   subtitle:
  //     "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos illo quod repudiandae nesciunt eos molestiae, quibusdam odit laborum cum perspiciatis labore incidunt autem fugit quisquam velit, esse est? Quis, maiores?",
  // },
  {
    id: 3,
    name: "Ridheema Chadha",

    subtitle:
      "Having developed various food allergies over the years, consuming simple and natural produce through LHC has made all the difference to my health. The added positive effect on climate change is an important bonus.",
  },
  {
    id: 4,
    name: "Nikhil Swaminathan",

    subtitle:
      "Health has always been a priority for our family and getting a convenient source of naturally farmed, nutritious and fresh produce has made it easier to incorporate healthier foods in our diets.",
  },
  
];
