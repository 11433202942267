import React from "react";
import Header from "../Header/Header";
import "./Impact.scss";
import Footer from "../Footer/Footer";

const Impact = () => {
  return (
    <div>
      <Header />
      <section className="content__section">
      <div className="page__content reverse" >
          <img src="/images/carousel1.jpg" alt="img" />
          <div className="text__content">
            <h1>Environmental Sustainability</h1>
            <p>
              With climate change and degradation of our natural environment.
              Sustainable practices have to take a centre stage. Through our CS
              Carbon farming initiative we ensure usage of a maximum number of
              regenerative and climate friendly practices which not only
              mitigate climate change but also enhance local ecology. This
              reduces the ecological footprint associated with conventional
              farming, promoting soil health, biodiversity, and water
              conservation. As community members support these sustainable
              practices, they actively contribute to the preservation of
              ecosystems and the long-term health of the planet.
            </p>
          </div>
        </div>
        <div className="page__content">
            <div>
            <img src="/images/3.jpg" alt="img" />
            </div>
          <div className="text__content">
            <h1>Strong sense of community engagement and support</h1>
            <p>
              By participating in community-supported agriculture (CSA)
              programs, you become an active stakeholder in your own food
              production. This not only strengthens the connection between
              farmers and consumers but also promotes a greater understanding of
              the agricultural process. As an active participant, you have
              control over what and how the food you consume is produced. You
              can participate by visiting the farm, engaging in community based
              discussions of recipes etc.
            </p>
          </div>
        </div>
        
        <div className="page__content reverse">
          <img src="/images/2.jpg" alt="img" />
          <div className="text__content">
            <h1>Helping fight Climate Change</h1>
            <p>
              Regenerative agricultural practices play a pivotal role in
              sequestering carbon from the atmosphere, contributing to climate
              change mitigation. Unlike conventional farming practices, which
              may deplete soil health and contribute to carbon emissions,
              regenerative agriculture focuses on enhancing soil fertility,
              biodiversity, and overall ecosystem resilience. Practices such as
              cover cropping, crop rotation and diversification, composting and
              organic amendments are just some of the ways through which we
              sequester carbon from the air into the soil.
            </p>
          </div>
        </div>
        <div className="page__content">
          <img src="/images/1.jpg" alt="img" />
          <div className="text__content">
            <h1>Nutrient-dense Produce</h1>
            <p>
              The fruits and vegetables available on commercial shelves today
              can contain less than half of their total potential nutritional
              value, due to the large-scale commercial farming practices. Our
              CSA ensures produce is grown naturally and without any chemical
              usage, which results in higher nutritional values which gives you
              maximum bang for your buck.
            </p>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Impact;
