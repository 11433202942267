export const CarouselData = [
  {
    id: 1,
    title:
      "Healthy Harvests for You and Mother Earth.",
    subtitle:"",
    // subtitle:
    //   "Naturally grown produce | Climate friendly practices | Your family farmer | Doorstep delivery within 5 hours of harvest",
    btn: "Join the waitlist",
    img:"/images/1.jpg"
  },
  // {
  //   id: 2,
  //   title:
  //     "LHC Community & Farmer Support",
  //   subtitle:
  //     "We curate and share recipes and detailed information with our community for convenient preparations and informed consumption decisions. Our partner farmers are provided minimum guaranteed income to provide their families financial security.      ",
  //   btn: "Join the waitlist",
  //   img:"/images/2.jpg"

  // },
  // {
  //   id: 3,
  //   title:
  //     "Climate Pledge",
  //   subtitle:
  //     "We train our partner farmers to use traditional regenerative agricultural practices while employing modern sustainable practices including drip irrigation, rain water harvesting, renewable energy, among others. We also implement soil carbon sequestration activities.",
  //   btn: "Learn More",
  //   img:"/images/3.jpg"
  // },
];
