import React from "react";
import "./Testimonials.scss";
import { TestimonialData } from "../../data/testimonialData";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const Testimonials = () => {
  const settings = {
    navigation: false,
    modules: [Navigation, Pagination, Autoplay],
    pagination: { clickable: true },
    loop: true,
    
    autoplay: {
      delay: 5000,
    },
    spaceBetween: 16,
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 1,
      },
      1024: {
        slidesPerView: 3,
      },
    },
  };
  return (
    <div className="testimonials">
      
      <h1>What our <span>community</span> say</h1>
      <Swiper {...settings}>
        {TestimonialData.map((item) => (
          <SwiperSlide key={item.id}>
            <div className="item">
              <div className="content">
                <h2 style={{fontStyle: "italic"}}>"{item.subtitle}"</h2>
                {/* <p>{item.subtitle}</p> */}
              </div>
              <div className="author">
                <h1>{item.name}</h1>
                {/* <img src={item.img} alt="author" /> */}
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Testimonials;
