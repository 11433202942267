import React from "react";
import "./Experience.scss";
import { ExperienceData } from "../../data/experienceData";

const Experience = () => {
  return (
    <div className="experience">
      <h1>
        Get the <span>Local Harvest</span> experience
      </h1>
      <div className="experience__cards">
        {ExperienceData.map((item) => (
          <div className="experience__card" key={item.id}>
            <img src={item.img} alt={item.title} />
            <div className="experience__card-info">
              <h3>{item.title}</h3>
              <p>{item.subtitle}</p>
              {/* <button>{item.btn}</button> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Experience;
