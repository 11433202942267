export const ExperienceData = [
  {
    id: 1,
    title: "An Investment In Your Health",
    subtitle:
      "Our naturally farmed vegetables are seasonal, non-GMO and chemical-free which ensures that you and your family receive the freshest and most nutritious food directly from the farm gate. Farm visits will be available soon.        ",
    btn: "Join the waitlist",
    img: "/images/2.jpg",
  },
  {
    id: 2,
    title: "Climate Pledge",
    subtitle:
      "We train our partner farmers to use traditional regenerative agricultural practices while employing modern sustainable practices including drip irrigation, rain water harvesting, renewable energy, among others. We also implement soil carbon sequestration activities.",
    btn: "Join the waitlist",
    img: "/images/3.jpg",
  },
  {
    id: 3,
    title: "LHC Community & Farmer Support",
    subtitle:
      "We curate and share recipes and detailed information with our community for convenient preparations and informed consumption decisions. Our partner farmers are provided minimum guaranteed income to provide their families financial security.        ",
    btn: "Join the waitlist",
    img: "/images/1.jpg",
  },
];
