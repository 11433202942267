/* global dataLayer */

import React, { useEffect } from "react";
import "./App.scss";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import FAQs from "./components/FAQs/FAQs";
import Home from "./components/Home/Home";
import Impact from "./components/Impact/Impact";

const App = () => {
  useEffect(() => {
    // Initialize Google Analytics
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-KQHCDV9SKX';
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-KQHCDV9SKX');
  }, []); // The empty array ensures this effect runs only once after initial render

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/Impact">
          <Impact />
        </Route>
        <Route path="/FAQs">
          <FAQs />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
