import React from "react";
import "./Navbar.scss";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  const location = useLocation();

  return (
    <div className="navbar">
      <ul>
        {/* <li className='active'>Home</li> */}
        <Link to="/Impact" style={{ textDecoration: "none" }} >
        <li className={location.pathname==="/impact"&&"active"}>Impact</li>

        </Link>
        <Link to="/FAQs" style={{ textDecoration: "none" }} >
          <li className={location.pathname==="/FAQs"&&"active"}>FAQs</li>
        </Link>
        {/* <li>Contact</li> */}
      </ul>
    </div>
  );
};

export default Navbar;
