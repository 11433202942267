// Carousel.js
import React from 'react';
import './Carousel.scss';
import { CarouselData } from '../../data/carouselData';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


const Carousel = () => {
  const settings = {
    navigation: false,
    modules: [Navigation, Pagination, Autoplay] ,
    pagination: { clickable: true },
    loop: true,
    autoplay: {
      delay: 5000, 
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 1,
      },
      1024: {
        slidesPerView: 1,
      },
    },
  };

  return (
    <div className='carousel'>
      <Swiper {...settings}>
        {CarouselData.map((item) => (
          <SwiperSlide key={item.id}>
            <div className="item">
              <img src={item.img} alt={item.title} />
              <div className="content">
                <h1>{item.title}</h1>
                <p>{item.subtitle}</p>
                {/* <button>{item.btn}</button> */}
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Carousel;
