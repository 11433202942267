import React from "react";
import "./Curves.scss";

const Curves = ({ bgColor, children, top=true, bottom=true }) => {
  return (
    <div className="background" style={{ backgroundColor: bgColor }}>
        {top&&<div className="top-curve"></div>}
        {children}
        {bottom&&<div className="bottom-curve"></div>}
    </div>
  );
};

export default Curves;
