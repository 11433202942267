import "./Contact.scss";
import React, { useEffect, useState, useRef } from "react";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const formRef = useRef();
  const [error, setError] = useState("");

  useEffect(() => {
    if (error === "Email sent successfully") {
      setTimeout(() => {
        // setError("");
        console.log("error set to false");
      }, 3000);
    }
  }, [error]);
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm("service_8r9como", "template_jchg09e", formRef.current, "rQmAqUfTABpAWtIJz")
      .then(
        (result) => {
          console.log(result.text);
          setError("Email sent successfully");
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
          setError("Error sending email");
        }
      );
  };

  return (
    <div className="contact__form">

      <div className="text__container">
        {/* <h3>Join us as an early partner</h3> */}
        <h1 >Sign up for the waitlist to join our community</h1>
        <p>
          Our team will get back to you as soon as new slots open up. We respect
          your privacy and do not send promotional messages.
          <br />
          <br />
          
          Current expected
          wait time for limited slots : 1-4 weeks (Dec 2023 -Jan 2024).
        </p>
      </div>
      <div className="form__container">
        <form onSubmit={sendEmail} ref={formRef}>
          <div className="input__field">
            <label htmlFor="first__name">Name</label>
            <input
              type="text"
              placeholder="Eg. Buzz Aldrin"
              required
              name="name"
            />
          </div>

          <div className="input__field">
            <label htmlFor="email">Email</label>
            <input
              type="text"
              placeholder="Eg. buzz@nasa.com"
              required
              name="email"
            />
          </div>
          <div className="input__field">
            <label htmlFor="pincode">Pincode</label>
            <input
              type="text"
              placeholder="Eg. 120045"
              required
              name="pincode"
            />
          </div>
          <div className="input__field">
            <label htmlFor="phone">Phone Number</label>
            <input
              type="text"
              placeholder="Eg.1234567890"
              required
              name="phone"
            />
          </div>
          {error === "Email sent successfully" ? (
            <button
              disabled
              style={{ backgroundColor: "green", color: "white" }}
            >
              Email Sent Successfully
            </button>
          ) : (
            <button type="submit">Submit</button>
          )}
        </form>
      </div>
    </div>
  );
};

export default Contact;
